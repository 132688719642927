import React, {useState, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import { Combobox as RWCombobox } from 'react-widgets'

import './styles.css'

const Combobox = ({className, filter, errors, value, disabled, ...props}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (disabled || props.busy) setOpen(false)
  }, [disabled, props.busy])

  const messages = {
    emptyList: "Essa lista ainda está sendo carregada",
    emptyFilter: "Sem resultados para a busca"
  }

  const openListOnInputFocus = (event) => {
    if (event.target.nodeName === "INPUT"){
      setOpen(true)
    }
  }

  return (
    <Form.Group controlId="exampleForm.ControlInput2">
      <RWCombobox
        messages={messages}
        className={`${errors ? "is-invalid" : ""} ${value && !errors ? "is-valid" : ""} ${className}`}
        value={value}
        filter={filter || "contains"}
        open={open}
        onFocus={openListOnInputFocus}
        onToggle={open => setOpen(open)}
        disabled={disabled}
        {...props}
      />
      <Form.Control.Feedback  className="loga-invalid-feedback" type="invalid">
        {errors}
      </Form.Control.Feedback>
    </Form.Group>
  )
}



export default Combobox