import {post} from 'services/http'

export const saveLeads = async (uid, data) => {
  const body = {
    data
  }
  uid && (body.uid = uid)

  const response = await post(
    'gravaLeadInfos', 
    body
  )

  return response
}