import React from 'react'
import {Row, Col} from 'react-bootstrap'

import Input from 'components/Input'

import { handleChangeText, handleChangeTextMasked } from 'helpers/inputChanges'

import BoxPhone from 'components/Box/Phone'

import { patterns } from 'commons'

const Portability = ({
  phoneNumber, mobileOperator, setPhoneNumber, setMobileOperator,
  formDisabled,
  ...props
}) => {

  return (
    <>
        <Row className="align-items-md-center mt-4" >
            <Col md="6">
                <Input 
                    type="text" 
                    maxLength={15}
                    name="phoneNumber"
                    placeholder="Qual seu telefone?"
                    onChange={event => handleChangeTextMasked(event, setPhoneNumber, patterns.phone)}
                    className="border-light-gray"
                    disabled={formDisabled}
                    {...phoneNumber}
                />
              
            </Col>
            <Col md="6">
                <Input 
                    type="text"
                    name="mobileOperator"
                    placeholder="Operadora"
                    onChange={event => handleChangeText(event, setMobileOperator)}
                    disabled={formDisabled}
                    {...mobileOperator}
                />
            </Col>
          </Row>
    </>
  )
}

export default Portability