import { get, post } from 'services/http'

export const verifyClientUpgrade = async (uid) => await get(`checkClientUpgrade/${uid}`);

export const createUpgradeAttendance = async ({ contractNumber, uid }) => {
  const body = {
    numeroContrato: contractNumber,
  };

  const response = await post(`createUpgradeAttendance/${uid}`, body);

  return response;
}
