import React from 'react'
import {Row, Col} from 'react-bootstrap'

import {GoCalendar} from 'react-icons/go'

import './styles.css'

const BoxLeadTime = ({plan}) => {
  return (
    <div className="box">
      <p className="title">Prazo de Instalação</p>
      <Row>
        <Col md="4">
          <div className="badget">
            <div id="icon"> <GoCalendar /> </div>
            <div id="text">Até {plan.prazo} dias úteis</div>
          </div>
        </Col>
        <Col md="8" className="align-self-center">
          <p className="description">
            Esse prazo é gerado pelo sistema. Mas nosso time também vai entrar em contato com você para confirmar a data da sua instalação.
            {plan.adequacao ? "O seu prédio ainda não está adequado para receber a nossa fibra e o processo de adequação tem um prazo de 20 dias úteis para ser concluído após a autorização do síndico." : ""}
          </p>
        </Col>
      </Row>
    </div>
  )
}

export default BoxLeadTime