import React, {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import { mask } from "remask"
import './styles.css'

import {patterns} from 'commons'

const InstallationResidencial = ({allData}) => {
  const rua = allData?.data?.endereco_informado.endereco || allData?.data?.endereco.endereco
  const {cidade, uf, cep} = allData?.data?.endereco
  const cepMasked = mask(cep, patterns.cep)
  
  return (
    <Row>
      <Col md="11">
        <div className="resume-title">Instalação</div>
      </Col>
      <Col md="6">
        <div className="resume-data"><b>Rua:</b> {rua}</div>
        <div className="resume-data"><b>Cep:</b> {cepMasked}</div>
      </Col>
      <Col md="6" className="resume-separator-col-border-left">
        <div className="resume-data"><b>Cidade:</b> {cidade}</div>
        <div className="resume-data"><b>UF:</b> {uf}</div>
      </Col>
      <Col md="11">
        <div className="resume-separator-horizontal"/>
      </Col>
    </Row>
  )
}

export default InstallationResidencial