import React from 'react'
import {Row, Col, Collapse} from 'react-bootstrap'
import Input from 'components/Input'
import {handleChangeText} from 'helpers/inputChanges'

const BuildingFound = ({
    floor, setFloor,
    apartmentNumber, setApartmentNumber,
    tower, setTower,
    wing, setWing,
    notFindBuilding,
    formDisabled
}) => {
  return (
    <Collapse in={!notFindBuilding}>
      <div>
        <Row>
          <Col md="3">
            <Input 
              type="text" 
              placeholder="Andar"
              onChange={event => handleChangeText(event, setFloor)}
              disabled={formDisabled}
              {...floor}
            />
          </Col>
          <Col md="3">
            <Input 
              type="text" 
              placeholder="Nº apartamento"
              onChange={event => handleChangeText(event, setApartmentNumber)}
              disabled={formDisabled}
              {...apartmentNumber}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Input 
              type="text" 
              placeholder="Torre"
              onChange={event => handleChangeText(event, setTower)}
              disabled={formDisabled}
              {...tower}
            />
          </Col>
          <Col md="3">
            <Input 
              type="text" 
              placeholder="Ala"
              onChange={event => handleChangeText(event, setWing)}
              disabled={formDisabled}
              {...wing}
            />
          </Col>
        </Row>
      </div>
    </Collapse>
  )
}

export default BuildingFound