import { saveLeads } from "./saveLeads"

export const savePlanNegocio = async (uid) => {
  const data = {
    viabilidade: {
      tipo: "negocio"
    }
  }

  const response = await saveLeads(uid, data)
  return response
}

export const saveInviabilidadePredial = async (
  uid, 
  predio, numero_andares, numero_apartamentos, torre, ala, rua, bairro, numero
) =>{
  const data = buildInviabilidadePredial(
    predio, numero_andares, numero_apartamentos, torre, ala, rua, bairro, numero
  )

  const response = await saveLeads(uid, data)
  return response
}

const buildInviabilidadePredial = (
  predio, numero_andares, numero_apartamentos, torre, ala, rua, bairro, numero
) => {
  const data = { 
    viabilidade: {
      tipo: "inviabilidade_predial",
    },
    inviabilidade_predial: {
      predio: predio.value,
      numero_andares: numero_andares.value,
      numero_apartamentos: numero_apartamentos.value, 
      torre: torre.value, 
      ala: ala.value,
      rua: rua.value, 
      bairro: bairro.value, 
      numero: numero.value
    }
  }

  return data
}

export const savePlanPredial = async (
  uid, 
  predio, andar, numero, torre, ala, 
  plano, dia_fatura, portabilidade, mobileOperator, phoneNumber
) =>{
  const data = buildPlanPredial(
    predio, andar, numero, torre, ala, 
    plano, dia_fatura, portabilidade, mobileOperator, phoneNumber
  )

  const response = await saveLeads(uid, data)
  return response
}

const buildPlanPredial = (
  predio, andar, numero, torre, ala, 
  plano, dia_fatura, portabilidade, mobileOperator, phoneNumber
) => {
  const data = { 
    viabilidade: {
      tipo: "predial",
      edificio: predio.value.id,
      andar: andar.value,
      apartamento: numero.value, 
      torre: torre.value, 
      ala: ala.value
    },
    ...buildPlanData(plano, dia_fatura, portabilidade, mobileOperator, phoneNumber)
  }

  return data
}

export const savePlanResidencial = async (uid, plano, dia_fatura, portabilidade, mobileOperator, phoneNumber) => {
  const data = buildPlanResidencial(plano, dia_fatura, portabilidade, mobileOperator, phoneNumber)

  const response = await saveLeads(uid, data)
  return response
}

const buildPlanResidencial = (plano, dia_fatura, portabilidade, mobileOperator, phoneNumber) => {
  const plan = buildPlanData(plano, dia_fatura, portabilidade, mobileOperator, phoneNumber)

  return {
    ...plan
  }
}


const buildPlanData = (plano, dia_fatura, portabilidade, mobileOperator, phoneNumber) => {
  return {
    plano: {
      id: plano.value.id,
    },
    agendamento: {
      dia_fatura: dia_fatura.value
    },
    portabilidade: {
      portabilidade: portabilidade !== '' ? portabilidade : 'nao',
      numero: phoneNumber.value || '',
      operadora: mobileOperator.value || ''
    },
  }
}