import React, {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import { mask } from "remask"
import './styles.css'

import {patterns} from 'commons'

import {GrNext, GrPrevious} from 'react-icons/gr'

import OrangeButton from 'components/Button/Orange'
import OutlineButton from 'components/Button/Outline'
import Spinner from 'components/Spinner'

import Modal from 'components/Modal'

import BoxLeadTimeResume from 'components/Box/LeadTimeResume'
import InstallationResidencial from './InstallationResidencial'
import InstallationPredial from './InstallationPredial'

import Checkbox from 'components/Checkbox'
import getPlanFromData from 'helpers/getPlanFromData'

import {finishOrder} from 'services/resume'

const Resume = ({uid, type, allData, setAllData, pageOpen, setPageOpen, setFinishType, setShowModal}) => {
  const [formDisabled, setFormDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [acceptData, setAcceptData] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)

  const [showTerms, setShowTerms] = useState(false)
  const [plan, setPlan] = useState({})

  useEffect(() => { window.gtag('event', 'pageview', {'name': 'resumo'}) }, [])
  useEffect(() => { setPlan(getPlanFromData(allData)) }, [])
  
  const {nome, cpf, email, data_nascimento, telefone} = allData?.data?.usuario
  const {cidade, uf} = allData?.data?.endereco
  const {dia_fatura} = allData?.data?.agendamento
  const {regulamento} = allData?.data?.plano

  const telefoneMasked = mask(telefone, patterns.phone)
  const cpfMasked = mask(cpf, patterns.cpf)

  const next = async () => {
    if (!acceptData || !acceptTerms) return
    setFormDisabled(true)
    setLoading(true)
    const response = await finishOrder(uid)

    handleResponse(response)

    setFormDisabled(false)
    setLoading(false)
  }

  const handleResponse = (response) => {
    if (!response.success) 
      return setShowModal({title: "Erro", message: "Não foi pssível se conectar com os servidores"})

    if (response.data.status === 0) 
    return setShowModal({title: "Atenção", message: response.data.message })

    setAllData(response.data)

    const finishType = plan.adequacao ? "inappropriateBuilding" : "success"
    setFinishType(finishType)
  }

  const Installation = type === "predial" ? InstallationPredial : InstallationResidencial

  return (
    <>
      <Row>
        <Col md="11">
          <div className="resume-title">Seu plano</div>
        </Col>
        <Col md="2">
          <div className="resume-plan-data">Plano <b>{plan.nome}</b></div>
          <div className="resume-plan-separator-horizontal" />
          <div className="resume-plan-data"><b>{plan.preco}</b>/mês</div>
        </Col>
        <Col md="2" className="resume-separator-col-border-left">
          <div className="resume-plan-data">Adesão</div>
          <div className="resume-plan-separator-horizontal" />
          <div className="resume-plan-data"><b>{plan.adesao}</b></div>
        </Col>
        <Col md="2" className="resume-separator-col-border-left">
          <div className="resume-plan-data">Vencimento Fatura</div>
          <div className="resume-plan-separator-horizontal" />
          <div className="resume-plan-data"><b>{dia_fatura}/mês</b></div>
        </Col>
        <Col md="5" className="resume-separator-col-border-left">
          <div className="resume-plan-detail">
            • Oferta exclusiva para {cidade}-{uf} <br/>
            • Fibra óptica até seu ONU/MODEM <br/>
            • Wi-Fi grátis
          </div>
          <div 
            className="resume-plan-detail-link"
            onClick={() => setShowTerms(true)}
          >{"Confira o regulamento do seu plano>>"}</div>
        </Col>
        <Col md="11">
          <div className="resume-separator-horizontal"/>
        </Col>
      </Row>
      <Row>
        <Col md="11">
          <div className="resume-title">Seus dados</div>
        </Col>
        <Col md="6">
          <div className="resume-data"><b>Nome completo:</b> {nome}</div>
          <div className="resume-data"><b>CPF:</b> {cpfMasked}</div>
          <div className="resume-data"><b>E-mail:</b> {email}</div>
        </Col>
        <Col md="6" className="resume-separator-col-border-left">
          <div className="resume-data"><b>Data de nascimento:</b> {data_nascimento}</div>
          <div className="resume-data"><b>Telefone:</b> {telefoneMasked}</div>
        </Col>
        <Col md="11">
          <div className="resume-separator-horizontal"/>
        </Col>
      </Row>

      <Installation
        allData={allData}
      />

      <Row>
        <Col md="11">
          <BoxLeadTimeResume 
            plan={plan}
          />
        </Col>
      </Row>
      <Row>
        <Col md="11">
          <Checkbox 
            label="Estou de acordo com os dados acima."
            value={"aceito"}
            valueChecked={acceptData}
            onChange={value => setAcceptData(value)}
            disabled={formDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col md="11">
          <Checkbox 
            label="Li e concordo com os termos da contratação"
            value={"aceito"}
            valueChecked={acceptTerms}
            onChange={value => setAcceptTerms(value)}
            disabled={formDisabled}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs="5" md="3">
          <OutlineButton 
            onClick={() => setPageOpen(pageOpen - 1)}
            disabled={formDisabled}
          >
            <GrPrevious /> Corrigir
          </OutlineButton>
        </Col>
        <Col xs="5" md="4">
          <OrangeButton
            disabled={!acceptData || !acceptTerms || formDisabled}
            onClick={next}
          >
            Concluir <GrNext/>
          </OrangeButton>
        </Col>
        <Col xs="2" md="1" className="ml-auto">
          {
            (loading) && <Spinner />
          }
        </Col>
      </Row>

      <Modal 
        show={showTerms}
        onHide={() => setShowTerms(false)}
        header="Regulamento e Informações adicionais"
        body={<div dangerouslySetInnerHTML={{ __html: regulamento}} />}
        footer={
          <> 
            <OutlineButton 
              onClick={() => setShowTerms(false)}
            >
              Fechar
            </OutlineButton>
          </>
        }
      />
    </>
  )
}

export default Resume