import React from 'react'
import {Row, Col} from 'react-bootstrap'

import {GoCalendar} from 'react-icons/go'

import './styles.css'

const BoxInput = ({plan}) => {
  return (
    <div className="box">
      <p className="title blue">Prazo de Instalação</p>
      <Row>
        <Col md="12">
          <p className="description">
            <GoCalendar /> 
            Sua instalação será efetuada em até {plan.prazo} dias úteis. 
            Após a confirmação do pedido, nosso time vai entrar em contato para agendar o procedimento.
            {plan.adequacao ? "O seu prédio ainda não está adequado para receber a nossa fibra e o processo de adequação tem um prazo de 20 dias úteis para ser concluído após a autorização do síndico." : ""}
          </p>
        </Col>
      </Row>
    </div>
  )
}

export default BoxInput