import React, {useState, useEffect} from 'react'
import {Row, Col, Collapse} from 'react-bootstrap'

import {GrNext, GrPrevious} from 'react-icons/gr'

import Input from 'components/Input'
import Map from 'components/Map'
import CircleValidator from 'components/CircleValidator'
import Spinner from 'components/Spinner'
import OrangeButton from 'components/Button/Orange'
import OutlineButton from 'components/Button/Outline'

import { getLatLng, getAddress } from 'services/geocoding'
import { saveAddressLatLng } from 'services/address'
import { handleChangeText } from 'helpers/inputChanges'
import { isEmpty } from 'helpers/validateFields'

const Address = ({uid, allData, setAllData, setPageOpen, setFinishType, setShowModal}) => {
  const [formDisabled, setFormDisabled] = useState(false)
  const [loading, setLoading] = useState(false)

  const [street, setStreet] = useState({value: ""})
  const [district, setDistrict] = useState({value: ""})
  const [number, setNumber] = useState({value: ""})
  const [city, setCity] = useState({value: ""})

  const [mapOpened, setMapOpened] = useState(false)
  const [marker, setMarker] = useState(null)
  const [formattedAddress, setFormattedAddress] = useState("")

  useEffect(() => { window.gtag('event', 'pageview', {'name': 'endereço'}) }, [])

  useEffect(() => {
    if (allData){
      setStreet({value: allData.data?.endereco?.endereco})
      setDistrict({value: allData.data?.endereco?.bairro})
      setCity({value: allData.data?.endereco?.cidade})
    }
  }, [])

  const next = async () => {
    setLoading(true)
    setFormDisabled(true)

    if (!mapOpened){
      await openMap()
    } else {
      await saveAddress()
    }

    setLoading(false)
    setFormDisabled(false)
  }

  const saveAddress = async () => {
    const response = await saveAddressLatLng(uid, number, street, district, marker.position)

    handleResponse(response)
  }

  const handleResponse = (response) => {
    if (!response.success) 
      return setShowModal({title: "Erro", message: "Não foi pssível se conectar com os servidores"})

    if (response.data.status === 0) 
    return setShowModal({title: "Atenção", message: response.data.message })

    setAllData(response.data)
    if (response.data.data.viabilidade_status === 1){
      setPageOpen(4)
    }else {
      setFinishType("unsupportedRegion")
    }
  }

  const openMap = async () => {
    if (!validateForm()) return

    const response = await getLatLng(street, number, district, city)

    if (response.data.status === "OK") {
      setMarker({
        title: "Sua casa",
        name: "",
        position: response.data.results[0].geometry.location
      })
      setFormattedAddress(response.data.results[0].formatted_address)
      setMapOpened(true)
    }
  }

  const changeMarker = async ({title, name, position}) => {
    if (loading) return
    setLoading(true)
    setMarker({title, name, position})
    const response = await getAddress(position)

    if (response.data.status === "OK") {
      setFormattedAddress(response.data.results[0].formatted_address)
      setLoading(false)
    }
  }

  const editAddress = () => {
    setMapOpened(false)
    setFormattedAddress("")
    setMarker(null)
  }

  const validateForm = () => {
    const valid = !isEmpty(
      [street, number, district],
      [setStreet, setNumber, setDistrict]
    )

    return valid
  }

  return (
    <>
    <Collapse in={!mapOpened}>
      <div>
        <Row>
          <Col md="11">
            <Input 
              type="text"
              placeholder="Rua"
              onChange={event => handleChangeText(event, setStreet)}
              disabled={formDisabled}
              {...street}
            />
          </Col>
          <Col md="1" className="mt-2">
            <CircleValidator
              show={street.value || street.errors}
              valid={street.value && !street.errors}
            />
          </Col>
        </Row>
        <Row>
          <Col md="5">
            <Input 
              type="text"
              placeholder="Número"
              onChange={event => handleChangeText(event, setNumber)}
              disabled={formDisabled}
              {...number}
            />
          </Col>
          <Col md="6">
            <Input 
              type="text"
              placeholder="Bairro"
              onChange={event => handleChangeText(event, setDistrict)}
              disabled={formDisabled}
              {...district}
            />
          </Col>
          <Col md="1" className="mt-2">
              <CircleValidator
                show={number.value || district.value}
                valid={(number.value && !number.errors) && (district.value && !district.errors)}
              />
          </Col>
        </Row>
      </div>
    </Collapse>
    {
      marker &&
      <Collapse in={mapOpened}>
        <Row className="">
          <Col md="11">
            <Input 
              type="text"
              placeholder="Endereço"
              disabled={true}
              value={formattedAddress}
            />
          </Col>
          <Col md="11">
            <Map 
              marker={marker}
              setMarker={changeMarker}
              loading={loading}
            />
          </Col>
          <Col md="11">
            <div 
              className="link"
              onClick={editAddress}
            >
              {"< editar o endereço"}
            </div>
          </Col>
        </Row>
      </Collapse>
    }
    <Row className="mt-4">
        <Col xs="5" md="3" >
          <OutlineButton 
            onClick={() => setPageOpen(2)}
            disabled={formDisabled}
          >
            <GrPrevious /> Anterior
          </OutlineButton>
        </Col>
        <Col xs="5" md="3" >
          <OrangeButton
            onClick={next}
            disabled={formDisabled}
          >
            Proximo <GrNext/>
          </OrangeButton>
        </Col>
        <Col xs="2" md="1" className="ml-auto mt-1">
          {
            (loading) && <Spinner />
          }
        </Col>
      </Row>
    </>
  )
}

export default Address