import React from 'react'
import './styles.css'
import {FiCheck} from 'react-icons/fi'

const Checkbox = ({label, value, valueChecked, onChange, disabled}) => {
  
  const onClick = () => {
    if (disabled) return
    onChange(value)
  }

  return (
    <div
      className={`loga-checkbox ${value === valueChecked ? "check" : ""} ${disabled ? "disabled" : ""}`}
      onClick={onClick}
    >
      <div id="input"><FiCheck/></div>
      <div id="label">{label}</div>
    </div>
  )
}

export default Checkbox