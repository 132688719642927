import {get} from 'services/http'

export const getCidadesLoga = async () => {
  const response = await get("getCidadesLoga")

  return response.data
}

export const getPredios = async (cidade = "") => {
  const response = await get("edificios/get/predios", {cidade}, null, "https://assine2.loga.net.br")

  if (response.success) return response.data.result
  return false
}