import axios from 'axios'

import {successObject, errorObject} from 'services/http'

const mapsApiUrl = "https://maps.googleapis.com/maps/api/geocode/json"
const key = "AIzaSyCFLImLqKyuYZgsP-PZTmuEzF5jdSbw2SA"
const language = "pt-BR"

export const getLatLng = async (street, number, district, city) => {
  const params = {
    key,
    language,
    address: `${street.value}, ${number.value}, ${district.value}, ${city.value}`
  }

  try {
    const response = await axios.get(
      mapsApiUrl, 
      {params},
    )
    
    return successObject(response);
  } catch (error) {
    return errorObject(error);
  }
}

export const getAddress = async ({lat, lng}) => {
  const params = {
    key,
    language,
    latlng: `${lat},${lng}`
  }

  try {
    const response = await axios.get(
      mapsApiUrl, 
      {params},
    )
    return successObject(response);
  } catch (error) {
    return errorObject(error);
  }
}