import { mask, unMask } from "remask";

export const handleChangeTextMasked = (event, set, pattern) => {
  const { value } = event.target;

  const originalValue = unMask(value);
  const maskedValue = mask(originalValue, pattern);

  set({ value: maskedValue, errors: "", originalValue });
};

export const handleChangeText = (event, set, options = {}) => {
  let { value } = event.target;
  options.upperCase && (value = value.toUpperCase())
  set({ value, errors: "" });
};