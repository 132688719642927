import React, {useState, useEffect} from 'react'
import {Form, Row, Col, Collapse} from 'react-bootstrap'

import {GrNext, GrPrevious} from 'react-icons/gr'

import OrangeButton from 'components/Button/Orange'
import OutlineButton from 'components/Button/Outline'
import Spinner from 'components/Spinner'
import BoxPhone from 'components/Box/Phone'

import ChoosePlanForm from 'components/ChoosePlanForm'
import ChooseBuildingForm from 'components/ChooseBuildingForm'
import Portability from 'components/Portability'

import Checkbox from 'components/Checkbox'

import { getPredios } from 'services/getLists'
import { savePlanResidencial, savePlanPredial, saveInviabilidadePredial, savePlanNegocio } from 'services/choosePlan'
import { isEmpty, isSelected } from 'helpers/validateFields'

const days = ["05", "10", "15", "20", "25"]

const ChoosePlan = ({
  uid, 
  allData, setAllData, 
  setPageOpen, 
  type, setType,
  notFindBuilding, setNotFindBuilding,
  setFinishType,
  setShowModal
}) => {
  const [formDisabled, setFormDisabled] = useState(false)
  const [loading, setLoading] = useState(false)

  const [buildings, setBuildings] = useState([])
  const [building, setBuilding] = useState({value: ""})
  
  const [buildingName, setBuildingName] = useState({value: ""})
  const [street, setStreet] = useState({value: ""})
  const [number, setNumber] = useState({value: ""})
  const [district, setDistrict] = useState({value: ""})
  
  const [floor, setFloor] = useState({value: ""})
  const [apartmentNumber, setApartmentNumber] = useState({value: ""})
  const [tower, setTower] = useState({value: ""})
  const [wing, setWing] = useState({value: ""})

  const [plans, setPlans] = useState([])
  const [plan, setPlan] = useState({value: ""})
  const [dueDate, setDueDate] = useState({value: "05"})

  const [planHasPhone, setPlanHasPhone] = useState(false)
  const [portabilidade, setPortabilidade] = useState("")
  const [phoneNumber, setPhoneNumber] = useState({value: ""})
  const [mobileOperator, setMobileOperator] = useState({value: ""})

  useEffect(() => { window.gtag('event', 'pageview', {'name': 'escolha_plano'}) }, [])

  useEffect(() => {
    getAddress()
    getBuildings()
  }, [])
  useEffect(() => { getPlans() }, [allData])

  useEffect(() => {

    function clearPhoneFields(){
      setPlanHasPhone(false)
      setPortabilidade('nao')
      setPhoneNumber({ value: '' })
      setMobileOperator({ value: '' })
    }
    
    if(plan.value !== ''){
      if(plan.value.telefonia){
        setPlanHasPhone(true);
      } else {
        clearPhoneFields()
      }
    } else {
      clearPhoneFields()
    }

  },[plan])

  useEffect(() => {

    if(portabilidade === 'nao'){
      setPhoneNumber({ value: '' })
      setMobileOperator({ value: '' })
    }

  },[portabilidade])

  const getBuildings = async () => {
    setBuilding({...building, busy: true, disabled: true})

    const cidade = allData.data?.endereco?.cidade || allData.data?.endereco_informado?.cidade
    const predios = await getPredios(cidade)

    if (predios) {
      const prediosFormatted = predios.map(predio => { return {
        ...predio,
        title: `${predio.text} - ${predio.end}`
      }})

      setBuildings(prediosFormatted)
      setBuilding({value: ""})
    }
  }

  const getPlans = () => {
    const planos = allData.data.planos || []

    const plans = planos.map(plan => { return {
      ...plan,
      title: `${plan.nome} - R$ ${plan.promo_preco}/mês ${plan.adesao ? `(+ adesão R$ ${plan.adesao},00)` : "sem adesão"}`
    }})
    setPlans(plans)
  }

  const getAddress = () => {
    setStreet({value: allData.data?.endereco?.endereco})
    setDistrict({value: allData.data?.endereco?.bairro})
  }

  const next = async () => {
    if (!type) return
    setFormDisabled(true)
    setLoading(true)

    switch (type){
      case "residencial":
        await residencialNext()
        break;
      case "predial":
        notFindBuilding ? 
          await inviabilidadePredialNext() 
          : 
          await predialNext()

        break;
      case "negocio":
        await negocioNext()
        break;
    }

    setLoading(false)
    setFormDisabled(false)
  }

  const negocioNext = async () => {
    const response = await savePlanNegocio(uid)
    
    handleResponse(response, () => setFinishType("business"))
  }

  const inviabilidadePredialNext = async () => {
    if (!validateNotFindBuildingForm()) return

    const response = await saveInviabilidadePredial(
      uid, 
      buildingName, floor, apartmentNumber, tower, wing, street, number, district)
    
    handleResponse(response, () => setFinishType("buildingNotFound"))
  }

  const predialNext = async () => {
    if (!validateBuildingForm() || !validPlanForm()) return

    const response = await savePlanPredial(uid, building, floor, apartmentNumber, tower, wing, plan, dueDate, portabilidade, mobileOperator, phoneNumber)
    handleResponse(response)
  }

  const residencialNext = async () => {
    if (!validPlanForm()) return

    const response = await savePlanResidencial(uid, plan, dueDate, portabilidade, mobileOperator, phoneNumber )
    handleResponse(response)
  }

  const handleResponse = (response, callback) => {
    if (!response.success) 
      return setShowModal({title: "Erro", message: "Não foi possível se conectar com os servidores" })

    if (response.data.status === 0) 
      return setShowModal({title: "Atenção", message: response.data.message })

    if (callback) return callback()

    setAllData(response.data)
    setPageOpen(3)
  }

  const validateNotFindBuildingForm = () => {
    const valid = !isEmpty(
      [buildingName, street, number, district],
      [setBuildingName, setStreet, setNumber, setDistrict]
    )

    return valid
  }

  const validateBuildingForm = () => {
    let valid = !isEmpty(
      [building, floor, apartmentNumber],
      [setBuilding, setFloor, setApartmentNumber]
    )
    valid = isSelected(building, setBuilding, buildings, "Selecione um prédio da lista") && valid
    
    return valid
  }

  const validPlanForm = () => {
    let valid = !isEmpty(
      [plan, dueDate],
      [setPlan, setDueDate]
    )
    valid = isSelected(plan, setPlan, plans, "Selecione um plano da lista") && valid
    valid = isSelected(dueDate, setDueDate, days, "Selecione uma data de válidade da lista") && valid

    return valid
  }

  return (
    <Form>
      <Row className="align-items-md-center mt-4" >
        <Col md="4">
          <Checkbox 
            label="Meu Apartamento"
            value="predial"
            valueChecked={type}
            onChange={value => setType(value)}
            disabled={formDisabled}
          />
        </Col>
        <Col md="4">
          <Checkbox 
            label="Minha Casa"
            value="residencial"
            valueChecked={type}
            onChange={value => setType(value)}
            disabled={formDisabled}
          />
        </Col>
        <Col md="4">
          <Checkbox 
            label="Meu Negócio"
            value="negocio"
            valueChecked={type}
            onChange={value => setType(value)}
            disabled={formDisabled}
          />
        </Col>
      </Row>
      <Collapse 
        in={type === "predial"}
        timeout={600}
      >
        <div>
          <ChooseBuildingForm 
            {...{
              buildings,
              building, setBuilding,
              buildingName, setBuildingName,
              street, setStreet,
              number, setNumber,
              district, setDistrict,
              floor, setFloor,
              apartmentNumber, setApartmentNumber,
              tower, setTower,
              wing, setWing,
              notFindBuilding, setNotFindBuilding,
              formDisabled
            }}
          />
        </div>
      </Collapse>
      <Collapse 
        in={type === "residencial" || (type === "predial" && !notFindBuilding)}
        timeout={600}
      >
        <div>
          <ChoosePlanForm 
            plans={plans}
            days={days}
            plan={plan}
            setPlan={setPlan}
            dueDate={dueDate}
            setDueDate={setDueDate}
            formDisabled={formDisabled}
          />
        </div>
      </Collapse>
      <Collapse 
        in={planHasPhone}
        timeout={600}
      >
        <div>
          <Row className="align-items-md-center mt-4" >
            <Col md="12">
              <div className="App-sub-title">Deseja fazer portabilidade?</div>
            </Col>
            <Col md="4">
              <Checkbox 
                label="Sim"
                value="sim"
                valueChecked={portabilidade}
                onChange={value => setPortabilidade(value)}
                disabled={formDisabled}
              />
            </Col>
            <Col md="4">
              <Checkbox 
                label="Não"
                value="nao"
                valueChecked={portabilidade}
                onChange={value => setPortabilidade(value)}
                disabled={formDisabled}
              />
            </Col>
          </Row>
        </div>
      </Collapse>

      <Collapse 
        in={portabilidade === "sim" && planHasPhone}
        timeout={600}
      >
        <div>
          <Portability 
            mobileOperator={mobileOperator}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setMobileOperator={setMobileOperator}
            formDisabled={formDisabled}
          />
        </div>
      </Collapse>
      
      <Row className="mt-4">
        <Col xs="5" md="3">
          <OutlineButton 
            onClick={() => setPageOpen(1)}
            disabled={formDisabled}
          >
            <GrPrevious /> Anterior
          </OutlineButton>
        </Col>
        <Col xs="5" md="3">
          <OrangeButton
            disabled={!type || formDisabled}
            onClick={next}
          >
            Proximo <GrNext/>
          </OrangeButton>
        </Col>
        <Col xs="2" md="1" className="ml-auto">
          {
            (loading) && <Spinner />
          }
        </Col>
      </Row>
    </Form>
  )
}

export default ChoosePlan