import React, {useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'

import OrangeButton from 'components/Button/Orange'
import BoxProtocol from 'components/Box/Protocol'
import { empresa } from '../commons';

const Finish = ({allData, finishType}) => {

  useEffect(() => {
    if (finishType){
      window.gtag('event', 'pageview', {'name': 'finalização'}) 
      window.scrollTo(0, 0)
    }
  }, [finishType])

  const nome = allData?.data?.usuario.nome
  const protocolo = allData?.data?.protocolo

  const defaultTitle = <>
    Parabéns {nome}!
    <br/>
    <br/>
    Agora você faz parte do Universo {empresa.nome} ;)
  </>

  const messages = {
    unsupportedRegion: {
      title: <>Muito obrigado pelo contato {nome}</>,
      description: <>
        Ficamos muito felizes com seu interesse na nossa Internet. Infelizmente, nossa cobertura 100% Fibra Óptica ainda não atende seu endereço.
        <br/>
        <br/>
          Mas assim que a gente chegar por aí, nós vamos entrar em contato. Combinado?
      </>
    },
    business: {
      title: <>
        Muito obrigado {nome}
        <br/>
        <br/>
        Agora falta pouco para você se tornar um {empresa.singular}
      </>,
      description: <>
        Ficamos muito felizes com o seu interesse na nossa Internet. 
        Já já alguém do nosso time vai entrar em contato com você pra continuar seu atendimento.
      </>
    },
    buildingNotFound: {
      title: <>Muito obrigado pelo contato {nome}</>,
      description: <>
        Ficamos muito felizes com seu interesse na nossa Internet. Infelizmente, nossa cobertura 100% Fibra Óptica ainda não atende no seu prédio. 
        <br/><br/>
        Mas assim que a gente chegar por aí, nós vamos entrar em contato. Combinado?
      </>
    },
    inappropriateBuilding: {
      title: defaultTitle,
      description: <>
        Ficamos muitos felizes com seu interesse na nossa internet. 
        Pode deixar que o nosso time vai entrar em contato com o síndico do seu prédio para realizar a adequação.
        <br/>
        <br/>
        Logo depois, a gente volta se falar pra agendar a instalação da sua internet {empresa.nome}. 
        <br />
        Combinado?
      </>
    },
    success: {
      title: defaultTitle,
      description: <>
        Estamos muito animados em ter você com a gente. 
        Já já nosso time vai entrar em contato pra confirmar alguns dados e agendar sua instalação. 
        Combinado?
      </>
    }
  }

  const {title, description} = messages[finishType] || messages.success

  const renderProtocol = protocolo &&
    <Row>
      <Col md="11">
        <BoxProtocol 
          protocol={protocolo}
        />
      </Col>
    </Row>

  return (
    <>
      <Row>
        <Col md="12" className="mt-4">
          <h1 className="App-title">
            {title}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md="9" className="mt-4 mb-4">
          <h3 className="App-description">
            {description}
          </h3>
        </Col>
      </Row>
      {renderProtocol}
      <Row>
        <Col md="4" className="mt-4">
          <OrangeButton 
            onClick={() => window.open(`${empresa.site}`,"_self")}
          >
            Voltar para home
          </OrangeButton>
        </Col>
      </Row>
    </>
  )
}

export default Finish