export default (allData) => {
  const viabilidade_status = allData?.data.viabilidade_status
  const planos = allData?.data?.planos
  const plano_id = allData?.data?.plano.id

  const planosFiltered = planos.filter(function (plano) {
    return plano.id === plano_id
  });

  const adesao = planosFiltered[0].adesao ? `R$${planosFiltered[0].adesao},00` : "Gratuita"
  const adequacao = !(viabilidade_status === 1)
  const prazo = planosFiltered[0].prazo + (adequacao ? 20 : 0)

  return {
    nome: planosFiltered[0].nome,
    preco: `R$${planosFiltered[0].promo_preco}`,
    adesao,
    prazo,
    adequacao
  }
}