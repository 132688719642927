import { saveLeads } from "./saveLeads"

export const savePersonalDataCep = async (uid, cep) => {
  const data = buildPersonalDataCep(cep)

  const response = await saveLeads(uid, data)
  return response
}

const buildPersonalDataCep = (cep) => {
  return {
    endereco_informado: {
      cep: cep.originalValue
    }
  }
}

export const savePersonalData = async (uid, nome, email, telefone, cidade) => {
  const data = buildPersonalData(nome, email, telefone, cidade)

  const response = await saveLeads(uid, data)
  return response
}

export const buildPersonalData = (nome, email, telefone, cidade) => {
  return {
    usuario: {
      nome: nome.value,
      email: email.value,
      telefone: telefone.originalValue,
    },
    endereco_informado: {
      cidade: cidade.value.cidade,
      ibge: cidade.value.ibge
    }
  }
}