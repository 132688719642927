import React from 'react'
import {Button} from 'react-bootstrap'

import './styles.css'

const OrangeButton = ({children, className = "", ...props}) => {

  return (
    <Button
      className={`btn-loga-outline ${className}`}
      {...props}
    >
      {children}
    </Button>
  )
}

export default OrangeButton