import React from 'react'
import {Row, Col} from 'react-bootstrap'

import OutlineButton from 'components/Button/Outline'
import OrangeButton from 'components/Button/Orange'
import Combobox from 'components/Combobox'
import CircleValidator from 'components/CircleValidator'

import BuildingFound from './BuildingFound'
import BuildingNotFound from './BuildingNotFound'

const ChoosePlanForm = ({
  buildings,
  building, setBuilding,
  notFindBuilding, setNotFindBuilding,
  formDisabled,
  ...props
}) => {

  const childrenProps = {
    ...props,
    notFindBuilding, setNotFindBuilding,
    formDisabled
  }

  const toggleNotFindBuild = () => {
    props.setWing({value: ""})
    props.setFloor({value: ""})
    props.setApartmentNumber({value: ""})

    !notFindBuilding ? setBuilding({value: "", disabled: true}): setBuilding({value: ""})
    setNotFindBuilding(!notFindBuilding)
  }

  const renderNotFindOrFindButton = 
    <OutlineButton
      disabled={formDisabled}
      onClick={() => toggleNotFindBuild()}
    >
      {notFindBuilding ? "Buscar meu prédio na lista" : "Não encontro meu prédio"}
    </OutlineButton>

  return (
    <>
    <Row className="mt-4">
      <Col md="7">
        <Combobox 
          data={buildings}
          placeholder="Selecione seu prédio"
          textField="title"
          onChange={value => setBuilding({value, errors: ""})}
          disabled={formDisabled}
          {...building}
        />
      </Col>
      <Col md="4">
        {renderNotFindOrFindButton}
      </Col>
      <Col md="1">
        <CircleValidator 
          show={building.value}
          valid={building.value && !building.errors}
        />
      </Col>
    </Row>
    <BuildingFound 
      {...childrenProps}
    />
    <BuildingNotFound 
      {...childrenProps}
    />
    </>
  )
}

export default ChoosePlanForm