import React, {useEffect, useState} from 'react'
import {Form, Row, Col} from 'react-bootstrap'

import {GrNext} from 'react-icons/gr'

import OrangeButton from 'components/Button/Orange'
import BoxPhone from 'components/Box/Phone'
import Input from 'components/Input'
import Combobox from 'components/Combobox'
import CircleValidator from 'components/CircleValidator'
import Spinner from 'components/Spinner'

import { savePersonalData, savePersonalDataCep } from 'services/personalData'
import {getCidadesLoga} from 'services/getLists'
import { isEmpty, isEmail, isPhone, isCep, isSelected, isAllName } from 'helpers/validateFields'
import { handleChangeText, handleChangeTextMasked } from 'helpers/inputChanges'
import { patterns } from 'commons'

const PersonalData = ({setPageOpen, uid, setUid, setAllData, setFinishType, setShowModal}) => {
  const [formDisabled, setFormDisabled] = useState(false)
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState({value: ""})
  const [email, setEmail] = useState({value: ""})
  const [phone, setPhone] = useState({value: ""})
  const [cep, setCep] = useState({value: ""})
  const [city, setCity] = useState({value: "", busy: true})

  const [cities, setCities] = useState([])

  useEffect(() => { window.gtag('event', 'pageview', {'name': 'informações_pessoais'}) }, [])

  useEffect(() => { getCities() }, [])
  useEffect(() => { isAllName(name, setName) }, [name.value])
  useEffect(() => { saveCep() }, [cep])

  const getCities = async () => {
    const cities = await getCidadesLoga()
  
    setCities(cities)
    setCity({value: ""})
  }

  const saveCep = async () => {
    if (cep?.originalValue?.length > 8) setCep({value: ""})
    if (!(cep?.originalValue?.length === 8 && !cep.disabled)) return

    setCity({...city, busy: true})
    setFormDisabled(true)
    const response = await savePersonalDataCep(uid, cep)

    if (response.success){
      handleCepResponse(response)
    }

    setFormDisabled(false)
  }

  const handleCepResponse = (response) => {
    const {uid, data: {endereco: {cidade}}} = response.data
    setUid(uid)

    if (cidade)
      setCityObj(cidade)
    else 
      setCity({...city, errors: "Não foi possível encontrar a cidade com o CEP fornecido"})
  }

  const setCityObj = (cityName) => {
    const cityObj = cities?.filter(function (obj) {
      return obj.cidade === cityName
    })[0]

    const value = cityObj || cityName
    setCity({value, disabled: true})
  }

  const handleResponse = (response) => {
    if (!response.success) 
      return setShowModal({title: "Erro", message: "Não foi pssível se conectar com os servidores"})

    if (response.data.status === 0) 
    return setShowModal({title: "Atenção", message: response.data.message })

    const cityIsSupported = cities?.includes(city.value)
    if (!cityIsSupported) return setFinishType("unsupportedRegion")

    setAllData(response.data)
    setPageOpen(2)
  }

  const next = async () => {
    if (!validateForm()) return

    setLoading(true)
    setFormDisabled(true)

    const response = await savePersonalData(uid, name, email, phone, city)

    handleResponse(response)
    setLoading(false)
    setFormDisabled(false)
  }

  const validateForm = () => {
    let valid = true
    valid = !isEmpty(
      [name, email, phone, cep, city],
      [setName, setEmail, setPhone, setCep, setCity]
    )
    valid = isPhone(phone, setPhone) && valid
    valid = isEmail(email, setEmail) && valid
    valid = isCep(cep, setCep) && valid
    valid = isAllName(name, setName) && valid

    if (!city.disabled){
      valid = isSelected(city, setCity, cities, "Selecione uma cidade de lista") && valid
    }

    return valid
  }

  return (
    <Form>
      <Row >
        <Col md="11">
          <Input 
            type="text" 
            name="name"
            placeholder="Nome completo"
            onChange={event => handleChangeText(event, setName, {upperCase: true})}
            disabled={formDisabled}
            {...name}
          />
        </Col>
        <Col md="1" className="mt-2">
          <CircleValidator
            show={name.value || name.errors}
            valid={name.value && !name.errors}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="11">
          <Input 
            type="text" 
            name="email"
            placeholder="E-mail"
            disabled={formDisabled}
            onChange={event => handleChangeText(event, setEmail)}
            {...email}
          />
        </Col>
        <Col md="1" className="mt-2">
          <CircleValidator
            show={email.value || email.errors}
            valid={email.value && !email.errors}
          />
        </Col>
      </Row>
      <Row className="align-items-md-center mt-2">
        <Col md="11">
          <BoxPhone 
            type="text" 
            maxLength={15}
            name="phone"
            placeholder="Qual seu telefone?"
            onChange={event => handleChangeTextMasked(event, setPhone, patterns.phone)}
            className="border-light-gray"
            disabled={formDisabled}
            {...phone}
          />
        </Col>
        <Col md="1">
          <CircleValidator
            show={phone.value || phone.errors}
            valid={phone.value && !phone.errors}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="6">
          <Input 
            type="text" 
            name="cep"
            placeholder="Cep"
            maxLength={9}
            onChange={event => handleChangeTextMasked(event, setCep, patterns.cep)}
            disabled={formDisabled}
            {...cep}
          />
        </Col>
        <Col md="5">
          <Combobox 
            data={cities}
            name="city"
            placeholder="Cidade"
            textField="cidade"
            onChange={value => setCity({value, errors: ""})}
            disabled={formDisabled}
            {...city}
          />
        </Col>
        <Col md="1" className="mt-2">
          <CircleValidator
            show={cep.value || city.value}
            valid={(cep.value && !cep.errors) && (city.value && !city.errors)}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="5" md='3'>
          <OrangeButton 
            onClick={next}
            disabled={formDisabled}
          >
            Próximo <GrNext/>
          </OrangeButton>
        </Col>
        <Col xs="2" md="1" className="ml-auto">
          {
            (loading || city.busy) && <Spinner />
          }
        </Col>
      </Row>
    </Form>
  )
}

export default PersonalData