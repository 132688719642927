import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import { GrPrevious, GrNext } from 'react-icons/gr'

import OrangeButton from 'components/Button/Orange'
import OutlineButton from 'components/Button/Outline'

import BoxLeadTime from 'components/Box/LeadTime'
import Input from 'components/Input'
import CircleValidator from 'components/CircleValidator'
import Spinner from 'components/Spinner'
import Modal from 'components/Modal'

import { savePersonalDocument } from 'services/personalDocument'
import { verifyClientUpgrade, createUpgradeAttendance } from 'services/upgrade'

import { isEmpty, isCpf, isDate } from 'helpers/validateFields'
import { handleChangeTextMasked } from 'helpers/inputChanges'
import { patterns } from 'commons'

import getPlanFromData from 'helpers/getPlanFromData'

const PersonalDocument = ({
  allData,
  pageOpen,
  setPageOpen,
  uid,
  setAllData,
  setShowModal,
  setProtocolNumber,
}) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [formDisabled, setFormDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [birthdate, setBirthdate] = useState({ value: "" })
  const [cpf, setCpf] = useState({ value: "" })
  const [plan, setPlan] = useState({})
  const [upgradeAttendanceInformations, setUpgradeAttendanceInformations] = useState({});

  useEffect(() => { window.gtag('event', 'pageview', { 'name': 'documentos_pessoais' }) }, [])

  useEffect(() => {
    setPlan(getPlanFromData(allData))
  }, [])

  const next = async () => {
    if (!validateForm()) return;
    setFormDisabled(true);
    setLoading(true);

    const savePersonalDocumentResponse = await savePersonalDocument(uid, cpf, birthdate);
    const verifyClientUpgradeResponse = await verifyClientUpgrade(uid);

    handleResponse({ 
      savePersonalDocumentResponse, 
      verifyClientUpgradeResponse 
    });

    setFormDisabled(false);
    setLoading(false);
  };

  const handleResponse = ({ 
    savePersonalDocumentResponse, 
    verifyClientUpgradeResponse 
  }) => {
    const activeContracts = verifyClientUpgradeResponse?.data.contratosAtivos;

    if (!savePersonalDocumentResponse.success)
      return setShowModal({ 
        title: "Erro", 
        message: "Não foi possível se conectar com os servidores" 
      });

    if (savePersonalDocumentResponse.data.status === 0)
      return setShowModal({ 
        title: "Atenção", 
        message: savePersonalDocumentResponse.data.message 
      });

    if (activeContracts) {
      setUpgradeAttendanceInformations({
        contractNumber: activeContracts,
        uid: savePersonalDocumentResponse.data.uid,
      });
      
      return setShowUpgradeModal(true);
    };

    setAllData(savePersonalDocumentResponse.data)
    setPageOpen(pageOpen + 1)
  }

  const validateForm = () => {
    let valid = !isEmpty(
      [cpf, birthdate],
      [setCpf, setBirthdate]
    )

    valid = isCpf(cpf, setCpf) && valid
    valid = isDate(birthdate, setBirthdate) && valid

    return valid
  }

  const handleUpgradeContract = async () => {
    const response = await createUpgradeAttendance({ 
      contractNumber: upgradeAttendanceInformations.contractNumber,
      uid: upgradeAttendanceInformations.uid
    });

    if (response.success) {
      setProtocolNumber(response.data.protocolo);
    }
  }

  return (
    <Form>
      <Row >
        <Col md="11">
          <Input
            type="text"
            name="cpf"
            placeholder="CPF"
            maxLength={14}
            onChange={event => handleChangeTextMasked(event, setCpf, patterns.cpf)}
            disabled={formDisabled}
            {...cpf}
          />
        </Col>
        <Col md="1" className="mt-2">
          <CircleValidator
            show={cpf.value || cpf.errors}
            valid={cpf.value && !cpf.errors}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="2" className="mt-2">
          <div className="loga-input-label">
            Data de nascimento
          </div>
        </Col>
        <Col md="4">
          <Input
            type="text"
            name="birthdate"
            placeholder="00/00/0000"
            maxLength={10}
            disabled={formDisabled}
            onChange={event => handleChangeTextMasked(event, setBirthdate, patterns.date)}
            {...birthdate}
          />
        </Col>
        <Col md="1" className="mt-2 ml-auto">
          <CircleValidator
            show={birthdate.value || birthdate.errors}
            valid={birthdate.value && !birthdate.errors}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <BoxLeadTime
            plan={plan}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs="5" md="3">
          <OutlineButton
            onClick={() => setPageOpen(pageOpen - 1)}
            disabled={formDisabled}
          >
            <GrPrevious /> Anterior
          </OutlineButton>
        </Col>
        <Col xs="5" md="3">
          <OrangeButton
            disabled={formDisabled}
            onClick={next}
          >
            Proximo <GrNext />
          </OrangeButton>
        </Col>
        <Col xs="2" md="1" className="ml-auto">
          {
            (loading) && <Spinner />
          }
        </Col>
      </Row>

      <Modal
        show={showUpgradeModal}
        onHide={() => setShowUpgradeModal(false)}
        header={'Upgrade de Instalação'}
        body={'Identificamos que já existe uma instalação realizada nesse endereço. Deseja realizar Upgrade da sua instalação atual?'}
        footer={
          <>
            <OutlineButton
              onClick={() => setShowUpgradeModal(false)}
            >
              Fechar
            </OutlineButton>
            <OrangeButton onClick={() => handleUpgradeContract()}>
              Fazer Upgrade
            </OrangeButton>
          </>
        }
      />
    </Form>
  )
}

export default PersonalDocument