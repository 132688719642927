import React from 'react'
import {Row, Col, Collapse} from 'react-bootstrap'
import Input from 'components/Input'
import CircleValidator from 'components/CircleValidator'
import {handleChangeText} from 'helpers/inputChanges'

const BuildingNotFound = ({
    buildingName, setBuildingName,
    street, setStreet,
    number, setNumber,
    district, setDistrict,
    floor, setFloor,
    apartmentNumber, setApartmentNumber,
    tower, setTower,
    wing, setWing,
    notFindBuilding,
    formDisabled
}) => {
  return (
    <Collapse in={notFindBuilding}>
      <div>
        <Row>
          <Col md="11">
            <Input 
              type="text" 
              placeholder="Qual é o nome do seu prédio?*"
              onChange={event => handleChangeText(event, setBuildingName)}
              disabled={formDisabled}
              {...buildingName}
            />
          </Col>
          <Col md="1" className="mt-2">
            <CircleValidator
              show={buildingName.value || buildingName.errors}
              valid={buildingName.value && !buildingName.errors}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Input 
              type="text" 
              placeholder="Nº de andares"
              onChange={event => handleChangeText(event, setFloor)}
              disabled={formDisabled}
              {...floor}
            />
          </Col>
          <Col md="3">
            <Input 
              type="text" 
              placeholder="Nº de apartamentos"
              onChange={event => handleChangeText(event, setApartmentNumber)}
              disabled={formDisabled}
              {...apartmentNumber}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Input 
              type="text" 
              placeholder="Torre"
              onChange={event => handleChangeText(event, setTower)}
              disabled={formDisabled}
              {...tower}
            />
          </Col>
          <Col md="3">
            <Input 
              type="text" 
              placeholder="Ala"
              onChange={event => handleChangeText(event, setWing)}
              disabled={formDisabled}
              {...wing}
            />
          </Col>
        </Row>
        <Row>
          <Col md="11">
            <Input 
              type="text"
              placeholder="Rua*"
              onChange={event => handleChangeText(event, setStreet)}
              disabled={formDisabled}
              {...street}
            />
          </Col>
          <Col md="1" className="mt-2">
            <CircleValidator
              show={street.value || street.errors}
              valid={street.value && !street.errors}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Input 
              type="text"
              placeholder="Número*"
              onChange={event => handleChangeText(event, setNumber)}
              disabled={formDisabled}
              {...number}
            />
          </Col>
          <Col md="4">
            <Input 
              type="text"
              placeholder="Bairro*"
              onChange={event => handleChangeText(event, setDistrict)}
              disabled={formDisabled}
              {...district}
            />
          </Col>
          <Col md="1" className="mt-2 ml-auto">
              <CircleValidator
                show={number.value || district.value}
                valid={(number.value && !number.errors) && (district.value && !district.errors)}
              />
          </Col>
        </Row>
      </div>
    </Collapse>
  )
}

export default BuildingNotFound