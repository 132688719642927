import React from 'react'
import './styles.css'
import { BsQuestion } from 'react-icons/bs';
import {FiCheck} from 'react-icons/fi'

const TabTitle = ({show, valid}) => {
  return (
    <div className={`circle-validator ${show ? "show" : ""} ${valid ? "valid" : ""}`}>
      <div id="icon">{valid ? <FiCheck/> : <BsQuestion />}</div>
    </div>
  )
}

export default TabTitle