import React from 'react'
import {Form} from 'react-bootstrap'
import './styles.css'

const Input = ({className, value, errors, ...props}) => {
  return (
    <Form.Group controlId="exampleForm.ControlInput1">
      <Form.Control 
        className={`loga-input ${className}`}
        isInvalid={errors}
        isValid={value && !errors}
        value={value}
        {...props}
      />
      <Form.Control.Feedback  className="loga-invalid-feedback" type="invalid">
        {errors}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default Input