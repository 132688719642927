import React, { useState, useEffect } from 'react';

import Tab from 'components/Tab'
import PersonalData from 'pages/PersonalData'
import ChoosePlan from 'pages/ChoosePlan'
import Address from 'pages/Address'
import PersonalDocument from 'pages/PersonalDocument'
import Resume from 'pages/Resume'
import UpgradedSuccessfully from 'pages/UpgradedSuccessfully';

import { empresa } from '../commons';

const FormFlow = (props) => {
  const [pageOpen, setPageOpen] = useState(1)
  const [progress, setProgress] = useState(1);
  const [type, setType] = useState("")
  const [notFindBuilding, setNotFindBuilding] = useState(false)
  const [protocolNumber, setProtocolNumber] = useState('');

  useEffect(() => { handleSetProgress(); }, [pageOpen]);

  const handleSetProgress = () => pageOpen > progress && setProgress(pageOpen);

  const commonsProps = {
    pageOpen,
    setPageOpen,
    progress,
    setProgress,
    type,
    setType,
    notFindBuilding,
    setNotFindBuilding,
    protocolNumber, 
    setProtocolNumber,
    ...props
  }

  const pagePersonalData = {
    number: 1,
    title: "Seus dados",
    pageComponent: <PersonalData
      {...commonsProps}
    />
  }

  const pageChoosePlan = {
    number: 2,
    title: "A Internet é para onde?",
    pageComponent: <ChoosePlan
      {...commonsProps}
    />
  }

  const pageAddress = {
    type: "residencial",
    number: 3,
    title: "Seu endereço",
    pageComponent: <Address
      {...commonsProps}
    />
  }

  const pagePersonalDocumment = {
    type: "residencial",
    number: 4,
    title: "Seu documento",
    pageComponent: <PersonalDocument
      {...commonsProps}
    />
  }

  const pageResume = {
    type: "residencial",
    number: 5,
    title: "Resumo do seu pedido",
    pageComponent: <Resume
      {...commonsProps}
    />
  }

  const pagePersonalDocummentB = {
    type: "predial",
    number: 3,
    title: "Seu documento",
    pageComponent: <PersonalDocument
      {...commonsProps}
    />
  }

  const pageResumeB = {
    type: "predial",
    number: 4,
    title: "Resumo do seu pedido",
    pageComponent: <Resume
      {...commonsProps}
    />
  }

  const renderDefaultContent = <>
    <h1 className="App-title">Agora falta pouco!</h1>
    <h3 className="App-description">Só mais alguns passos e você já vai aproveitar a Internet {empresa.nome}</h3>
    <div className="App-separator" />
    <Tab
      pageOpen={pageOpen}
      {...pagePersonalData}
    />
    <div className="App-separator" />
    {
      progress >= 2 &&
      <>
        <Tab
          pageOpen={pageOpen}
          {...pageChoosePlan}
        />
        <div className="App-separator" />
      </>
    }
    {
      (progress >= 3 && type === "residencial") &&
      <>
        <Tab
          pageOpen={pageOpen}
          {...pageAddress}
        />
        <div className="App-separator" />
      </>
    }
    {
      (progress >= 4 && type === "residencial") &&
      <>
        <Tab
          pageOpen={pageOpen}
          {...pagePersonalDocumment}
        />
        <div className="App-separator" />
      </>
    }

    {
      (progress >= 5 && type === "residencial") &&
      <>
        <Tab
          pageOpen={pageOpen}
          {...pageResume}
        />
      </>
    }

    {
      (progress >= 3 && type === "predial") &&
      <>
        <Tab
          pageOpen={pageOpen}
          {...pagePersonalDocummentB}
        />
        <div className="App-separator" />
      </>
    }

    {
      (progress >= 4 && type === "predial") &&
      <>
        <Tab
          pageOpen={pageOpen}
          {...pageResumeB}
        />
        <div className="App-separator" />
      </>
    }
  </>

  const renderContent = !protocolNumber
    ? renderDefaultContent
    : <UpgradedSuccessfully {...commonsProps} />

  return (
    <>
      {renderContent}
    </>
  )
}

export default FormFlow