import { saveLeads } from "./saveLeads"

export const savePersonalDocument = async (uid, cpf, data_nascimento) => {
  const data = buildPersonalDocument(cpf, data_nascimento)

  const response = await saveLeads(uid, data)
  return response
}

const buildPersonalDocument = (cpf, data_nascimento) => {
  return {
    usuario: {
      cpf: cpf.originalValue,
      data_nascimento: data_nascimento.value
    },
  }
}