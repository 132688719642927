import React from 'react'
import {Collapse} from 'react-bootstrap'
import TabTitle from 'components/Tab/Title'

const Tab = ({pageOpen, number, title, pageComponent}) => {
  
  return (
    <>
      <TabTitle 
        active={pageOpen === number}
        number={number}
        title={title}
      />
      <Collapse 
        in={pageOpen === number}
        timeout={600}
      >
        <div id={`collapse-${number}`}>
          {pageComponent}
        </div>
      </Collapse>
    </>
  )
}

export default Tab