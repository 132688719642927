import React from 'react'
import {Modal as BSModal} from 'react-bootstrap'

import './styles.css'

const Modal = ({show, onHide, header, body, footer}) => {

  return (
    <BSModal 
      show={show} 
      className="loga-modal"
      onHide={onHide}
      centered
    >
      <BSModal.Header closeButton={onHide}>
        <BSModal.Title>
          {header}
        </BSModal.Title>
      </BSModal.Header>

      <BSModal.Body>
        {body}
      </BSModal.Body>

      <BSModal.Footer>
        {footer}
      </BSModal.Footer>
    </BSModal>
  )
}

export default Modal