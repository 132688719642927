import React, {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import { mask } from "remask"
import './styles.css'

import {patterns} from 'commons'

const InstallationPredial = ({allData}) => {
  const {bairro, endereco, cidade, cep, uf} = allData?.data?.endereco
  const {nome} = allData?.data?.viabilidade_retorno?.predio
  const {andar, apartamento, torre, ala} = allData?.data?.viabilidade
  const cepMasked = mask(cep, patterns.cep)


  return (
    <Row>
      <Col md="11">
        <div className="resume-title">Instalação</div>
      </Col>
      <Col md="6">
        <div className="resume-data"><b>Nome do seu prédio:</b> {nome}</div>
        <div className="resume-data"><b>Andar:</b> {andar}</div>
        <div className="resume-data"><b>Apartamento:</b> {apartamento}</div>
        <div className="resume-data"><b>Torre:</b> {torre}</div>
        <div className="resume-data"><b>Ala:</b> {ala}</div>
      </Col>
      <Col md="6" className="resume-separator-col-border-left">
        <div className="resume-data"><b>Bairro:</b> {bairro}</div>
        <div className="resume-data"><b>Rua:</b> {endereco}</div>
        <div className="resume-data"><b>Cep:</b> {cepMasked}</div>
        <div className="resume-data"><b>Cidade:</b> {cidade}</div>
        <div className="resume-data"><b>UF:</b> {uf}</div>
      </Col>
      <Col md="11">
        <div className="resume-separator-horizontal"/>
      </Col>
    </Row>
  )
}

export default InstallationPredial