import './styles.css';
import React from 'react';

import OrangeButton from 'components/Button/Orange';

import { Row, Col } from 'react-bootstrap';
import { empresa } from '../../commons';

const UpgradedSuccessfully = ({ 
    allData: {
        data: {
            usuario: {
                nome
            }
        }
    },
    protocolNumber = '',
}) => {
    const navigateToHome = () => window.open(`${empresa.site}`, "_self");

    return (
        <>
            <h1 className="App-title">
                Parabéns {nome}!
            </h1>

            <h2 className="App-description">
                Obrigada pelo seu contato. Um atendimento <strong>{protocolNumber}</strong> foi aberto para tratativa do seu upgrade. 
                Em breve, nosso time entrará em contato.
            </h2>

            <Row>
                <Col xs="5" md='3'>
                    <OrangeButton onClick={navigateToHome}>
                        Voltar para Home
                    </OrangeButton>
                </Col>
            </Row>
        </>
    )
};

export default UpgradedSuccessfully;