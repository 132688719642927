import React from 'react'
import {Map as GoogleMap, Marker, GoogleApiWrapper} from 'google-maps-react'

import './styles.css'

const Map = ({google, marker, setMarker, loading}) => {
  const onClick = async (t, map, coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    await setMarker({
      title: "Sua casa",
      name: "",
      position: { lat, lng }
    })
  }

  return (
    <div className={`center-map ${loading ? "loading" : ""}`} >
      <GoogleMap
        google={google}
        style={{ borderRadius: "1rem", height: "20rem"}}
        containerStyle={{
          position: 'relative',  
          width: '100%',
          height: '100%'
        }}
        initialCenter={marker.position}
        className={"map"}
        zoom={16}
        onClick={onClick}
        streetViewControl={false}
      >
        <Marker
          title={marker.title}
          name={marker.name}
          position={marker.position}
        />
      </GoogleMap>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCFLImLqKyuYZgsP-PZTmuEzF5jdSbw2SA",
  language: 'pt-BR'
})(Map)