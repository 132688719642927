import React from 'react'
import './styles.css'

const TabTitle = ({active, number, title}) => {
  return (
    <div className={`tab-title ${active ? "active" : ""} `}>
      <div id="number">{number}</div>
      <div id="title">{title}</div>
    </div>
  )
}

export default TabTitle