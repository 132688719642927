import React from 'react'
import {Row, Col} from 'react-bootstrap'
import Input from 'components/Input'

import './styles.css'

const BoxInput = (props) => {
  return (
    <div className="box">
      <p className="title">Telefone para agendamento da instalação</p>
      <Row>
        <Col md="8">
          <Input 
            {...props}
          />
        </Col>
        <Col md="4">
          <p className="description">Nosso time vai utilizar esse número pra entrar em contato com você</p>
        </Col>
      </Row>
    </div>
  )
}

export default BoxInput