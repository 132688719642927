import React from 'react'
import {Row, Col} from 'react-bootstrap'

import Combobox from 'components/Combobox'
import CircleValidator from 'components/CircleValidator'

const ChoosePlanForm = ({
  plans, days,
  plan, setPlan,
  dueDate, setDueDate,
  formDisabled,
}) => {
  return (
    <>
    <Row className="mt-4">
      <Col md="11">
        <Combobox 
          data={plans}
          placeholder="Selecione o melhor plano para sua casa"
          textField="title"
          onChange={value => setPlan({value, errors: ""})}
          disabled={formDisabled}
          {...plan}
        />
      </Col>
      <Col md="1">
        <CircleValidator 
          show={plan.value}
          valid={plan.value && !plan.errors}
        />
      </Col>
    </Row>
    <Row className="mt-2">
      <Col md="6">
        <Row>
          <Col md="5" className="mt-2">
            <div className="loga-input-label">
              Dia de vencimento
            </div>
          </Col>
          <Col md="4" lg="3">
            <Combobox 
              data={days}
              onChange={value => setDueDate({value, errors: ""})}
              disabled={formDisabled}
              {...dueDate}
            />
          </Col>
        </Row>
      </Col>
      <Col md="5"/>
      <Col md="1">
        <CircleValidator 
          show={dueDate.value}
          valid={dueDate.value && !dueDate.errors}
        />
      </Col>
    </Row>
    </>
  )
}

export default ChoosePlanForm