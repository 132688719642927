import axios from 'axios'
import {url} from 'commons'

const defaultHeaders = {
  "Content-Type": "application/json",
  "Accept": "application/json"
}

export const get = async (endpoint = "", params = {}, headers = defaultHeaders, domain = url) => {
  try {
    const response = await axios.get(
      `${domain}/${endpoint}`, 
      {
        params,
        headers
      },
      
    )
    const obj = successObject(response);
    return obj
  } catch (error) {
    return errorObject(error);
  }
}

export const post = async (endpoint = "", body = {}, headers = defaultHeaders) => {
  try {
    const response = await axios.post(
      `${url}/${endpoint}`, 
      body,
      headers
    )
    const obj = successObject(response);
    return obj
  } catch (error) {
    return errorObject(error);
  }
}

export const successObject = (response) => {
  return {
    success: true,
    status: response.status,
    data: response.data,
  };
};

export const errorObject = (error) => {
  return {
    success: false,
    status: error.request.status,
    errors: errorsResponse(error),
  };
};

const errorsResponse = (error) => {
  try {
    const response = JSON.parse(error.request.response);
    return response.errors;
  } catch {
    return { error: "undefinied" };
  }
};