import { saveLeads } from "./saveLeads"

export const saveAddressLatLng = async (uid, numero, endereco, bairro, posicao) => {
  const data = buildAddress(numero, endereco, bairro, posicao)

  const response = await saveLeads(uid, data)
  return response
}

const buildAddress = (numero, endereco, bairro, posicao) => {
  return {
    endereco_informado: {
      numero: numero.value,
      endereco: endereco.value,
      bairro: bairro.value
    },
    viabilidade: {
      tipo: "residencial",
      lat: posicao.lat,
      lon: posicao.lng
    },
  }
}