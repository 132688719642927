import React from 'react'
import {Row, Col} from 'react-bootstrap'

import './styles.css'

const BoxProtocol = ({protocol}) => {
  return (
    <div className="box">
      <Row className="justify-content-center">
        <Col md="3" className="align-self-center">
          <div className="title blue ">Protocolo do seu pedido</div>
        </Col>
        <Col md="4" className="align-self-center">
          <div className="badget d-flex justify-content-center">
            <span id="text">{protocol}</span>
          </div>
        </Col>
        <Col md="5" className="align-self-center">
          <div className="description">
            Guarde esse número. 
            Com ele você pode consultar seu pedido com alguém do nosso time.
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default BoxProtocol